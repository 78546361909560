<template>
  <div>
    <cancel
            @click="refreshPage"
            :id="id"
    ></cancel>
  </div>
</template>
<script>
import cancel from "@/commonComponents/cancel.vue";

export default {
  props: ["isValid", "loading", "functionElement", "result","id"],
  components: {
    cancel,
  },
  methods: {
    refreshPage() {
      //this.$router.go();

      let path = this.$route.fullPath;
      path = path.replace("/", "");

      let saveLifeString = Math.random().toString(36).substring(7);
      this.$router
        .push(path + "&" + saveLifeString)
        .catch(() => {
          this.$router.go();
        })
        .then(() => {
          history.pushState({}, null, "?f=administration&f2=userSelfService&f3=myAccount");
        });
    },
  },
};
</script>